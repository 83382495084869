.categories__list-action{
  display: flex;
  place-content: space-around;
  align-items: center;
}


.categories__modal-delete-btn{
  display: flex;
  justify-content: flex-end;
  input{
    padding: 5px 25px;
    margin: 0;
  }
}
