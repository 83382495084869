.date__range-picker {
  width: auto;
  .react-datepicker__tab-loop {
    width: auto;
  }
  margin-right: 10px;
  .react-datepicker__day--in-selecting-range {
    color: #fff !important;
    background-color: $color-dark-green;
  }
}
