.finance__dashboard {
  .finance__dashboard-card-row {
    display: flex;
  }

  .download__transaction_report {
    padding: 3px 0px 0px 10px;
    color: #646777;
    float: right;
  }
}
