@import "../../settings/variable.scss";

.discussion_images_container {
  width: 100%;
  max-width: 800px;
  display: flex;
  padding: 20px 0 10px 15px;
  flex-wrap: nowrap;
  overflow: auto;
  background-color: $color-grey;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  .discussion_images_container::-webkit-scrollbar {
    display: none;
  }

  .img_container {
    margin: 0 30px 0 0px;
    align-self: center;
    position: relative;
    max-height: 80px;
    cursor: pointer;
    video {
      object-fit: cover;
    }
    img {
      width: 80px;
      height: 80px;
    }

    .img_close_icon {
      position: absolute;
      height: 28px;
      width: 28px;
      top: -15%;
      margin-left: -12%;
      cursor: pointer;
    }
  }
}
.mentioned_data {
  color: $color-gray-444 !important;
  word-break: break-all;
  a,
  span {
    color: $color-light-green3;
    cursor: pointer;
    font-weight: 600;
    text-decoration: none;
  }
}

.poll_question {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.cursor_pointer {
  cursor: pointer;
}

.disabled_content {
  cursor: not-allowed;
}
