.blogs__container {
    .custom__title_blogs {
        font-size: 12px;
        margin-bottom: 10px;
    }

    .blog__pad_left10 {
        padding-left: 10px;
    }
}
.download__blog_report {
    padding: 3px 0px 0px 0px;
    color: #646777;
    float: right;
}

.upload__blog_report {
    padding: 3px 10px 0px 0px;
    color: #646777;
    float: right;
}

.blog__error__message__container {
    margin-top: 10px;
    color: red;
}
.blog__margin__top40 {
    margin-top: 40px;
    position: absolute;
}

.blog__margin__top5 {
    margin-top: 5px !important;
}

.comments__blog_action {
    padding: 3px 10px 0px 0px;
    color: #646777;
    float: right;
}