@import "../../../../../scss/settings/variable.scss";

.container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.detail_container {
  display: flex;
  flex-direction: column;
}

.profile_picture {
  height: 48px;
  width: 48px;
  margin-right: 15px;
}

.profile_picture_initials {
  font-size: 20px;
}
