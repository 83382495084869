.material-form {
  .material-form__error {
    margin-top: -20px;
    color: $color-red;
  }

  .form__language {
    color: #006838;
  }

  .sticky__element {
    position: sticky;
    top: 60px;
    z-index: 999;
    background: #fff;
  }

  .material-form-error-image {
    margin-top: -8px;
    color: $color-red;
  }
  .material-form_inline-fields {
    display: flex;
    .materila-form_inline-child {
      flex: 1;
      margin-right: 25px;
    }
    .materila-form_inline-child:last-of-type {
      margin-right: 0;
    }
  }
  .material-form__field {
    width: 100% !important;
    margin-bottom: 20px !important;

    & > div:before {
      @include themify($themes) {
        border-bottom-color: themed("colorFieldsBorder");
      }
    }

    & > div:hover:before {
      border-bottom: 1px solid $color-accent !important;
    }

    & > div:after {
      border-color: $color-accent;
    }

    input,
    textarea {
      font-size: 12px;

      @include themify($themes) {
        color: themed("colorText");
      }
    }

    svg {
      @include themify($themes) {
        fill: themed("colorText");
      }
    }
  }

  .material-form__label {
    margin: 0;
    font-size: 12px;

    @include themify($themes) {
      color: themed("colorText");
    }
  }

  label {
    font-size: 12px;

    @include themify($themes) {
      color: themed("colorText");
    }

    &[data-shrink="true"] {
      transform: translate(0, 1.5px) scale(0.85);
    }
  }

  .material-form__radiogroup {
    margin-right: 80px;
    justify-content: space-between;
    @include themify($themes) {
      color: themed("colorText");
    }

    .MuiFormControlLabel-label {
      font-size: 12px;
    }

    svg {
      width: 20px;
      height: 20px;
      @include themify($themes) {
        fill: $color-accent;
      }
    }
  }
}

.material-form__radiogroup_column {
  @include themify($themes) {
    color: themed("colorText");
  }

  .MuiFormControlLabel-label {
    font-size: 12px;
  }

  svg {
    width: 20px;
    height: 20px;
    @include themify($themes) {
      fill: $color-accent;
    }
  }
}

.material-form__option {
  font-size: 13px !important;
  height: 18px !important;
}

.MuiInputBase-root {
  font-size: 12px !important;
  color: #646777 !important;
}

.disabled-icon {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
