.event__container {
  .custom__title_event {
    font-size: 12px;
    margin-bottom: 10px;
  }
}

.event_left_pad0 {
  padding-left: 0px !important;
  vertical-align: center;
}

.event_right_pad0 {
  padding-right: 0px !important;
  padding-left: 19px !important;
}

.event_top_pad10 {
  padding-top: 10px !important;
}

.event_requirements {
  border: 1px solid #d3d3d3;
  padding: 10px 10px 10px 15px;
  margin: 0 0 15px;
  border-radius: 5px;
}
