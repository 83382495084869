.modal {
}

.login-modal-form {
  .modal-content {
    padding-top: 20px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    height: 500px;
    overflow-y: auto;
  }
}

.modal-dialog {
  max-width: 385px;
}

.modal-content {
  //border-radius: 0;
  border: none;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  padding: 40px 40px 40px 40px;
  //text-align: center;

  @include themify($themes) {
    background-color: themed("colorBackground");
    color: themed("colorText");
  }
}

.modal__download-btn {
  position: absolute;
  right: 45px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  border: none;
  background: transparent;
  margin-bottom: 15px;
  top: calc(50% - 6px);
}

.modal__close-btn {
  position: absolute;
  top: 17px;
  right: 25px;
  @include directify($directions) {
    #{directed('right')}: 15px;
  }
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  //padding: 0;
  border: none;
  background: transparent;
  margin-bottom: 15px;

  @include themify($themes) {
    color: themed("colorIcon");
  }
}

.modal__title-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.modal__title {
  margin-top: 10px;
  margin-bottom: 15px;

  &:first-child {
    margin-top: 0;
  }
}

.modal__footer {
  //margin-top: 20px;
  display: flex;
  justify-content: center;
  //margin-bottom: 0;
  @include directify($directions) {
    flex-flow: directed("flex-flow") !important;
  }

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
  }
}

.modal-dialog--primary {
  .modal__title-icon {
    color: $color-blue;
  }
}

.modal-dialog--success {
  .modal__title-icon {
    color: $color-accent;
  }
}

.modal-dialog--warning {
  .modal__title-icon {
    color: $color-yellow;
  }
}

.modal-dialog--danger {
  .modal__title-icon {
    color: $color-red;
  }
}

.icon-btn-action {
  cursor: pointer;
  font-size: 2em !important;
}

.icon-broadcast {
  cursor: pointer;
  font-size: 1.8em !important;
  color: $color-gray;
}

.icon-file-info {
  cursor: pointer;
  font-size: 1.5em !important;
  @include themify($themes) {
    color: themed("colorText");
  }
}

.icon-btn-action-disable {
  opacity: 0.6;
  cursor: not-allowed;
  font-size: 25px !important;
}

.modal-dialog--colored {
  color: white;

  .modal__title-icon {
    color: white;
  }

  .modal__close-btn {
    color: white;
  }

  .modal__footer {
    button:first-child {
      color: #ffffff;
      background-color: rgba(255, 255, 255, 0.3);
      border-color: #ffffff;

      &:before {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }

    button:last-child {
      border-color: white;
      color: white;
    }
  }

  &.modal-dialog--primary .modal-content {
    background-color: $color-blue;
  }

  &.modal-dialog--success .modal-content {
    background-color: $color-accent;
  }

  &.modal-dialog--warning .modal-content {
    background-color: $color-yellow;
  }

  &.modal-dialog--danger .modal-content {
    background-color: $color-red;
  }
}

.modal-dialog--header {
  max-width: 520px;
  .modal-content {
    padding: 0;
    @include directify($directions) {
      text-align: directed("left");
    }
  }

  .modal__header {
    color: white;
    padding: 15px 20px;
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .modal__title {
    margin: 0;
    font-weight: 300;
    color: white;
    font-weight: bold;
  }

  .modal__close-btn {
    color: white;
    top: calc(50% - 8px);
  }

  .modal__body {
    @include directify($directions) {
      #{directed('padding-right')}: 40px;
      #{directed('padding-left')}: 20px;
    }
    padding: 25px;
  }

  .modal__footer {
    margin-bottom: 40px;
    justify-content: flex-end;
    @include directify($directions) {
      #{directed('padding-right')}: 20px;
    }
  }

  &.modal-dialog--primary .modal__header {
    background-color: $color-blue;
  }

  &.modal-dialog--success .modal__header {
    background-color: $color-accent;
  }

  &.modal-dialog--warning .modal__header {
    background-color: $color-yellow;
  }

  &.modal-dialog--danger .modal__header {
    background-color: $color-red;
  }

  &.modal-dialog--green .modal__header {
    background-color: $color-green;
  }

  &.modal-dialog--dark-green .modal__header {
    background-color: $color-dark-green;
  }
}
