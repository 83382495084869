.section__container {
    .custom__title_sections {
        font-size: 12px;
        margin-bottom: 10px;
    }

    .section__pad_left10 {
        padding-left: 10px;
    }

    .section__pad10 {
        padding: 10px;
    }
}
