@import "../../../../../scss/settings/variable.scss";

.container {
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  display: flex;
  // align-items: center;
  margin-bottom: 29px;
  margin-top: 24px;
  flex-direction: column;
  width: 420px;
  margin-left: 35%;
  img {
    width: 100%;
    height: 175px;
  }
  cursor: pointer;
  .detail_container {
    padding: 15px;
    .title {
      margin-bottom: 12px;
      font-weight: 600;
      line-height: 30px;
      font-size: 20px;
      color: $color-black3;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
  .image_container {
    img {
      border-radius: 8px 0px 0px 8px;
      width: 200px;
      height: 104px;
    }
  }

  .host_info {
    display: flex;
    align-items: center;
    margin-bottom: 21px;
    .profile_image {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    .initialsClassName {
      font-size: 12px;
      line-height: 19px;
      font-weight: 600px;
    }

    p {
      font-weight: 400;
      font-size: 15px;
      color: $color-gray-444;
      margin: 0;
      margin-left: 12px;
    }
  }

  @media only screen and (max-width: 999px) {
    background: $color-white;
    box-shadow: none;
    border-radius: 0;
    margin-top: 20px;
    margin-bottom: 16px;
    max-height: 49px;
    .image_container {
      img {
        border-radius: 50%;
        width: 49px;
        height: 49px;
      }
    }
    .detail_container {
      margin-left: 22px;
      .title {
        margin-bottom: 4px;
      }
    }
  }
  .host_info {
    .mweb_host_title {
      margin-left: 0;
      font-size: 14px;
    }
  }
}
