.finance__dashboard-card {
  .dashboard__card-widget {
    padding-top: 25px;
    cursor: pointer;
    .card__title {
      margin-bottom: 15px;
      .subhead {
        margin-top: 10px;
      }
    }
  }

  .dashboard__total {
    .dashboard__total-stat {
      margin-top: 40px;
    }

    .dashboard__total-stat {
      font-size: 18px;
      height: 24px;
      margin-top: auto;
      line-height: 24px;
      margin-right: auto;
      padding-right: 10px;
    }
  }
}
