.settings {
  .setting_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .MuiFormLabel-root,
  .material-form__error {
    text-transform: lowercase;
  }

  form {
    display: flex;
    align-items: center;
    div {
      margin-right: 25px;
    }
  }

  .card__title {
    margin-bottom: 0;
  }
}
