.privacy-policy__container{
  color: black;

  .privacy-policy__section {
    margin: 10px;
  }
  .privacy-policy__heading{
    font-size: 18;
    text-align: center;
    margin: 10 0;
  }
  .privacy-policy__title{
    font-size: 16;
    margin: 5 0;
  }
  .privacy-policy__bold-title{
    font-size: 16;
    font-weight: bold;
    margin: 5 0;
  }
  
  .privacy-policy-content{
    
  }
}
