.plan_left_pad0 {
  padding-left: 0px !important;
  vertical-align: center;
}

.plan_right_pad0 {
  padding-right: 0px !important;
  padding-left: 19px !important;
}

.plan_top_pad10 {
  padding-top: 10px !important;
}

.plan__status_button {
  margin-top: 20px;
}

.plan__variants {
  border: 1px solid #d3d3d3;
  padding: 10px 10px 10px 15px;
  margin: 0px 0px 15px 0px;
  border-radius: 5px;
}

.fieldset__box {
  border: 1px solid #d3d3d3;
  padding: 10px 10px 10px 15px;
  margin: 0px 0px 15px 0px;
  border-radius: 5px;
  legend {
    width: fit-content;
    p {
      font-size: 16px;
    }
  }
}
