.cancellation-section {
  margin: 10px;
}

.cancellation-title {
  color: black;
}

.cancellation-content {
  color: black;
}
