@import "../../../../../scss/settings/variable.scss";

.editor :global(.public-DraftEditor-content) {
  max-height: 180px;
  overflow-y: auto;
  font-size: 12px;
}

.single_line_editor {
  box-sizing: border-box;
  border: 0px;
  cursor: text;
  border-radius: 2px;
  background: fefefe;
}

.single_line_editor :global(.public-DraftEditor-content) {
  max-height: 180px;
  overflow-y: auto;
}

.single_line_editor :global(.public-DraftEditorPlaceholder-root) {
  color: grey;
  font-size: 14px;
}
