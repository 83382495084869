.progress-chip {
  width: auto;
  display: inline-block;
  position: relative;
  .circular-spinner {
    .MuiChip-label {
      margin-right: 25px;
    }
  }

  .MuiCircularProgress-root {
    width: 22px;
    height: 22px;
    transform: rotate(-90deg);
    margin: 0px 5px 0 -28px;
    position: absolute;
    bottom: 5px;
    color: $color-dark-green;
  }
}
