.attendees__list {
  height: 50px;
  display: flex;
  cursor: pointer;
  transition: all 0.3s;
  align-items: center;

  @include themify($themes) {
    border-bottom: 1px solid themed("colorFieldsBorder");
  }

  &:hover {
    @include themify($themes) {
      background-color: themed("colorHover");
    }
  }

  &.chat__contact--active {
    @include themify($themes) {
      background-color: themed("colorHover");
    }

    &:hover {
      @include themify($themes) {
        background-color: themed("colorHover");
      }
    }
  }
}

.attendees__contact-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  @include directify($directions) {
    #{directed('margin-right')}: 10px;
    #{directed('margin-left')}: 15px;
  }
  margin-top: auto;
  margin-bottom: auto;
  min-width: 40px;
  margin-right: 10px;

  img {
    height: 100%;
  }
}

.tambola__tickets {
  .tambola__tickets-1 {
    padding: 10px;
  }

  .tambola__tickets-2 {
    padding: 10px;
  }

  .MuiGrid-item {
    box-sizing: border-box;
    width: 50px;
    border: 1px solid #c0c0c0;
    text-align: inherit;
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .tambola__paper {
    color: rgba(0, 0, 0, 0.54);
    padding: 10px;
    text-align: center;
  }
}

.attendees__contact-preview {
  @include directify($directions) {
    #{directed('margin-right')}: 20px;
    #{directed('margin-left')}: 0px;
  }
  margin-top: auto;
  margin-bottom: auto;
  width: calc(100% - 80px);
}

.attendees__contact-name {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  color: $color-accent;
}

.attendees__contact-role {
  margin-top: 0;
  margin-bottom: 3px;
  font-size: 10px;
  line-height: 1.2;
  color: $color-additional;
}

.filter__reset-all {
  display: flex;
  position: relative;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;

  .filter-icon {
    font-size: 32px;
    color: lightcoral;
  }

  .remove-icon {
    position: absolute;
    color: white;
    background-color: lightcoral;
    right: -2px;
    font-size: 16px;
    top: 8px;
    border-radius: 50%;
  }
}

.schedule__booked-info {
  text-decoration: underline;
  color: #266838;
  &:hover {
    cursor: pointer;
    color: #00351d;
  }
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.action_review-schedule-event {
  position: relative;
  font-size: 18px;
  cursor: pointer;
  .user-icon {
    position: absolute;
    left: 4px;
  }
}

.review-schedule-event-container {
  .user-list {
    border-bottom: none;
  }
  .scheedule-event__review-text {
    text-align: start;
    padding: 4px;
    border-bottom: 1px solid #f2f4f7;
  }
  .scheedule-event__qna-container {
    padding: 4px;
    border-bottom: 1px solid #f2f4f7;
    .qna-sub-container {
      padding: 4px 0px;
      .question {
        font-weight: 500;
        padding-right: 4px;
      }
      .answer {
        padding-left: 4px;
      }
    }
  }
  .scheedule-event__tags-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 4px;
    .tags {
      padding: 4px 4px 4px 0;
    }
  }
  .schedule-event__no-review {
    text-align: center;
    font-size: 14px;
  }
  .schedule-event__no-attendees {
    text-align: center;
    font-size: 14px;
  }
}

.review-star-checked {
  color: orange;
}

.download__schedule_event_report {
  padding: 3px 0px 0px 10px;
  color: #646777;
  float: right;
}

.schedule_event__pad_left15 {
  padding-left: 15px;
}

.schedule__event_left_pad0 {
  padding-left: 0px !important;
  vertical-align: center;
}

.schedule__event_right_pad0 {
  padding-right: 0px !important;
  padding-left: 19px !important;
}

.schedule__event_top_pad10 {
  padding-top: 10px !important;
}

.schedule__copyUrl {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
  align-items: center;
  padding: 3px 10px;
  @include themify($themes) {
    border: 1px solid themed("colorBorder");
    color: themed("colorText");
  }

  &:last-of-type {
    border-bottom: none;
  }
}

.custom__div__dynamic__form {
  border: 1px solid #d3d3d3;
  padding: 10px 10px 10px 15px;
  margin: 0px 0px 15px 0px;
  border-radius: 5px;
}
.pad__top15 {
  padding-top: 15px;
}

.pad__right5 {
  padding-right: 5px;
}

.course__pad_left15 {
  padding-left: 15px;
  align-items: center;
}

.no__wrap {
  flex-wrap: nowrap;
}

.btn-pad {
  @media screen and (max-width: 1247px) {
    padding: 5px 10px !important;
  }
}

.commencement__date {
  margin-left: 10px;
  padding: 5px 10px 5px 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid $color-additional;
  color: $color-additional;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 20px;
  border-radius: 10px;
}
