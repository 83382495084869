@import "../../settings/variable.scss";

.meta_link {
  color: $color-black;
  text-decoration: underline;
  &:hover {
    color: $color-black;
    text-decoration: underline;
  }
}
