@import "../../../../../scss/settings/variable.scss";
.poll_container {
  margin: 21px 18px 4px 18px;
  border: 1px solid $color-grey-2;
  border-radius: 4px;
  padding: 16px;
  @media (min-width: 768px) {
    width: 324px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  button {
    text-transform: unset;
  }
}

.button_div {
  margin: 14px 0 12px 0;
  position: relative;
}
.button_container {
  height: 43px;
  margin-bottom: 10px;
}
.poll_button {
  background-color: transparent !important;
  border: 1px solid $color-light-green4 !important;
  border-radius: 4px !important;
  margin-bottom: 10px !important;
  text-align: left !important;
  color: $color-light-green4 !important;
  font-size: 15px !important;
  line-height: 150% !important;
  font-weight: 600 !important;
  padding: 10px 14px !important;
  height: 43px !important;
  width: 100% !important;
  display: flex !important;
  justify-content: flex-start !important;
}
.poll_button_percentage {
  // background-color: "linear-gradient(to right, #grey 50%, #a6c1ee 50%)" !important;
  border: 1px solid $color-light-green4 !important;
  border-radius: 4px !important;
  margin-bottom: 10px !important;
  text-align: left !important;
  color: $color-black3 !important;
  font-size: 15px !important;
  line-height: 150% !important;
  font-weight: 500 !important;
  padding: 10px 14px !important;
  height: 43px !important;
  position: relative !important;
  width: 100% !important;
  display: flex !important;
  justify-content: flex-start !important;
}
.counter {
  position: relative;
  top: -40px;
  left: 250px;
}

.dot {
  height: 5px;
  width: 5px;
  border-radius: 3px;
  background-color: $color-gray-777;
  margin: 0px 10px;
}

.poll_status_container {
  display: flex;
  align-items: center;
}
