.load {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  position: fixed;
  background: #ffffff;
  z-index: 1000;

  & + div {
    height: 100vh;
    overflow: hidden;
  }

  &.loaded {
    animation: ease loaded 0.5s;
  }
}

.load__icon {
  animation: linear load 2s infinite;
  margin: auto;
  width: 32px;
  height: 32px;
}

.load__icon-wrap {
  margin: auto;
}

@keyframes load {
  from {
    transform: rotate(0deg) scale(2);
  }
  to {
    transform: rotate(360deg) scale(2);
  }
}

@keyframes loaded {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.data-loader__overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(0, 0, 0, 0.6);
  .cv-spinner {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px #ddd solid;
    border-top: 4px $color-accent solid;
    border-radius: 50%;
    animation: sp-anime 0.8s infinite linear;
  }
  @keyframes sp-anime {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(359deg);
    }
  }
}

.table-loader__overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}
