[id^="mentions-list"] {
    max-height: 250px;
    overflow-y: scroll;
}

[id^="mentions-list"]::-webkit-scrollbar {
    background-color: transparent !important;
    scrollbar-width: 5px;
    width: 5px !important;
    display: block !important;
}

  /* scrollbar itself */
[id^="mentions-list"]::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #babac0;
}