@import "../settings//variable.scss";

.download__user_report,
.download__credit_usage,
.download__booking_history,
.download__payment_history {
  padding: 3px 0px 0px 10px;
  color: #646777;
  float: right;
}

.user__pad_left15 {
  padding-left: 15px;
}

.user_details_padLeft0 {
  padding-left: 0px !important;
}

.user_details_pad0 {
  padding: 0px !important;
  text-align: right;
}

.user__dob {
  margin-top: 20px;
}

.user__payment-link {
  margin-top: 50px;
  display: flex;
  width: 100%;
  .user__payment-link-text {
    border: 1px solid whitesmoke;
    padding: 10px;
  }

  .user__payment-link-icon {
    background: whitesmoke;
    padding: 10px;
  }
}

.users__accordion_tabs {
  background: $color-white !important;
  padding: 20px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
}

.users__status_multiselect{
  width: 230px;
  border-radius: 5px;
  border-bottom: none;
}