.label__container {
    .custom__background__color {
        margin-right: 20px !important;
        border: 1px solid;
    }

    .label__pad_left10 {
        padding-left: 10px;
    }
}
