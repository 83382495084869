.text-field-chips {
  margin-top: -5px;
  margin-bottom: 10px;
  .MuiAutocomplete-tag {
    text-overflow: ellipsis;
  }
}

.text-field-input {
  position: "relative";
}
.text-field-chip {
  max-width: 360px;
}
