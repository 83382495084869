@import "../../scss/settings/variable.scss";

.imageViewerContainer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: $color-black3 !important;
  height: 100% !important;
  overflow: none !important;
  position: fixed !important;
  overflow: auto !important;
  width: 100% !important;
  top: 0 !important;
  z-index: 99 !important;
  &::-webkit-scrollbar {
    display: none;
  }
  .close_video_modal {
    position: absolute;
    top: 20px;
    cursor: pointer;
    width: auto;

    @media only screen and(max-width:768px) {
      position: absolute;
      top: 50px;
      left: 1%;
      cursor: pointer;
    }
  }

  @media only screen and(max-width:768px) {
    height: 100% !important;
    width: 100% !important;
    left: 0 !important;
    margin: 0 !important;
    max-width: 100% !important;
  }
  img {
    cursor: pointer;
  }
}
.modal_video_container {
  position: relative;
  padding: 20px 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .close_video_modal {
    position: absolute;
    top: 20px;
    cursor: pointer;
    @media only screen and(max-width:768px) {
      position: absolute;
      top: 50px;
      left: 1%;
      cursor: pointer;
    }
  }
  .join_video_player {
    height: 455px;
  }

  video {
    height: 75vh !important;
    // width: inherit !important;
  }
}

.image_viewer {
  height: auto;
  max-height: 80vh;
  max-width: 100%;
  // height: 100vh;
}

.next_slider {
  position: absolute;
  right: 0;
}

.prev_slider {
  position: absolute;
  left: 0;
}

.image_container {
  justify-content: center;
}

.slider_image {
  height: 192px;
  object-fit: contain;
  padding-right: 20px;
}
.video_player_container {
  height: 192px;
}
.single_image {
  height: 361px;
  object-fit: contain;
  max-width: 100%;
}

.images_container {
  padding: 1px !important;
  margin: auto;
  width: 50%;
}

.background_image {
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.background_image_2 {
  @extend .background_image;
  margin-bottom: 5px;
}

.background_image_3 {
  @extend .background_image_2;
  height: 100% !important;
}

.background_image_4 {
  @extend .background_image_2;
  height: 195px !important;
  width: auto !important;
  margin-bottom: 2px;
}

.background_image_4:last-child {
  margin-bottom: 0 !important;
}

.background_image_5 {
  @extend .background_image_2;
  height: 195px !important;
  width: auto !important;
}

.background_image_6 {
  @extend .background_image_2;
  height: 175px !important;
  width: auto !important;
}

.background_image_7 {
  @extend .background_image_6;
  height: 155px !important;
}

.single_video_container {
  // background: $color-light-gray2;
  display: flex;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1px !important;
  video {
    object-fit: cover;
  }
}

.plus_button_container {
  width: 100%;
  // max-width: 1200px;
  margin: auto;
  display: block;
  position: relative;
  cursor: pointer;
  video {
    object-fit: cover;
  }
}

.plus_more_text {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.single_image_container {
  display: flex;
  justify-content: center;
}

.storyModalPaperCamera {
  background-color: $color-black !important;
  border-radius: 0 !important;
  padding: 0 !important;
}

.storyRoot {
  padding: 0 !important;
}
.modal_header {
  padding: 32px 24px;
}

.carousel_images {
  width: 100%;
}

.image_carousel_container {
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
}

.carousel_image {
  height: 80vh;
  width: auto !important;
  max-width: 100% !important;
}

.carousel_image_box {
  width: 100%;
  display: flex !important;
  justify-content: center !important;
}

.carousel_images > div > div {
  width: 100% !important;
}

.date_value_box {
  margin: 0px 40px;
  height: 56px;
  align-items: center;
  display: flex;
  flex: 1;
}

.active_date {
  background-color: $color-light-green3;
  color: $color-white !important;
  display: flex;
  padding: 14px 16px;
}

.banner_image {
  height: 249px;
  cursor: pointer;
}

.container_max_height {
  height: 400px;
}

.container_height_100_percent {
  height: 100%;
}
.media_size {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: relative;
}

.media_size:before {
  content: '';
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.media_fit_contain {
  object-fit: contain !important;
  video {
    object-fit: contain !important;
  }
}

.media_container {
  position: relative;
  video {
    object-fit: cover;
  }
}

.media_padding_bottom {
  padding-bottom: 2px;
}

.media_left_grid {
  padding-right: 2px;
}

.more_parent_relative {
  position: relative;
}

.more_img_box {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.mweb_play_button {
  @extend .more_img_box;
  background-color: transparent;
  cursor: pointer;
  img {
    width: unset;
  }
}

.player_button {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .container_max_height {
    height: 300px;
  }
  .date_container {
    justify-content: space-evenly;
  }
  .date_value_box {
    margin: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: none;
  }
  .active_date {
    border-radius: 6px;
  }
  .day_name {
    margin-right: 0px;
    text-transform: inherit;
    margin-top: 4px;
  }
  .active_date {
    padding: 9px;
  }
  .nex_arrow {
    height: 23px !important;
    width: 23px !important;
  }
  .prev_arrow {
    height: 23px !important;
    width: 23px !important;
  }
  .mweb_container {
    background-color: $color-black3 !important;
    overflow: hidden !important;
  }
  .mweb_image_container {
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  .mweb_image_container_center {
    justify-content: center;
  }
  .mweb_single_image {
    object-fit: contain;
    margin-bottom: 15px;
    width: 100%;
  }
  .mweb_multi_image {
    height: 228px;
    object-fit: cover;
    margin-bottom: 15px;
    width: 100%;
  }
  .mweb_image_caraousel_container {
    display: flex;
    overflow-x: auto;
    align-items: center;
  }
  .mweb_slider_image {
    height: 192px;
    width: 304px;
    object-fit: cover;
    margin-right: 16px;
  }
  .mweb_slider_video {
    height: 192px;
    width: 304px !important;
    margin-right: 16px;
  }
  .mweb_image_caraousel_container::-webkit-scrollbar {
    display: none;
  }
  .image_box {
    width: 100%;
    height: 228px;
    margin-bottom: 15px;
  }

  .image_box_single {
    height: 100%;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mweb_single_media {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .mweb_single_video {
    width: 100%;
    height: 100%;
    video {
      object-fit: contain;
    }
  }

  .mweb_single_video_container {
    height: 100%;
    width: 100%;
  }

  .mweb_multi_video_container {
    position: relative;
    height: 100%;
  }

  .mweb_multi_media {
    height: 100%;
    width: 100%;
    object-fit: cover;
    video {
      object-fit: cover;
    }
  }
}
