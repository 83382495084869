@import "../settings/variable.scss";

.multi-locale-days .rmdp-day {
  width: 40px;
  height: 40px;
}

.rmdp-container {
  width: 100% !important;
}

.rmdp-selected .sd {
  background-color: $color-accent !important;
  border-radius: 10% !important;
}

.rmdp-disabled span:hover {
  border-radius: 10% !important;
  background-color: $color-accent !important;
  color: $color-white !important;
}

.rmdp-day {
  height: 40px !important;
  width: 40px !important;
}

.sd {
  box-shadow: none !important;
}

.rmdp-today .sd {
  border: 1px solid $color-accent !important;
  border-radius: 10% !important;
  background: transparent !important;
  color: $color-black3 !important;
}

.rmdp-today.rmdp-selected .sd {
  border-radius: 10% !important;
  background-color: $color-accent !important;
  color: #fff !important;
}

.rmdp-today .sd:hover {
  border-radius: 10% !important;
  background-color: $color-accent !important;
  color: #fff !important;
}
.rmdp-day span:hover {
  background-color: $color-accent !important;
}

.rmdp-day.rmdp-today span {
  border: 1px solid $color-accent !important;
  background: transparent !important;
  color: $color-black3 !important;
  &:hover {
    background-color: $color-accent !important;
    color: #fff !important;
  }
}

.rmdp-selected span {
  background-color: $color-accent !important;
}

.rmdp-day-hidden span:hover {
  background: transparent !important;
}

.sd:hover {
  background-color: $color-accent !important;
  border-radius: 10% !important;
}

.rmdp-week-day {
  color: $color-accent !important;
}

.rmdp-arrow {
  border: solid #fff !important;
  border-width: 0 2px 2px 0 !important;
}

.rmdp-arrow-container:hover {
  background-color: #fff !important;
}

.rmdp-arrow-container:hover .rmdp-arrow {
  border: solid $color-accent !important;
  border-width: 0 2px 2px 0 !important;
}

.rmdp-top-class {
  min-width: 350px !important;
}

.rmdp-calendar {
  width: 100% !important;
  padding: 0 !important;
}

.rmdp-day-picker {
  display: block !important;
}

.rmdp-time-picker div input {
  outline: none !important;
}

.rmdp-wrapper {
  width: 150px !important;
}

.rmdp-time-picker {
  min-width: 150px !important;
}

.custom-calendar.rmdp-wrapper {
  width: auto !important;
}


.rmdp-header {
  margin-top: 0 !important;
  background-color: $color-accent !important;
}

.rmdp-header-values {
  color: #fff !important;
  font-weight: 800 !important;
}

.ep-arrow::after {
  background-color: $color-accent !important;
  box-shadow: none !important;
}
