.tnc__container{
  color: black;

  .tnc__section {
    margin: 10px;
  }
  .tnc__heading{
    font-size: 18;
    text-align: center;
    margin: 10 0;
  }
  .tnc__title{
    font-size: 16;
    margin: 5 0;
  }
  .tnc__bold-title{
    font-size: 16;
    font-weight: bold;
    margin: 5 0;
  }
  
  .tnc-content{
    
  }
}
