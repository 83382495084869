.color-picker {
  .color-picker__color {
    @include directify($directions) {
      #{directed('margin-right')}: auto;
      #{directed('margin-left')}: 0px;
    }
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    direction: ltr;
    @include directify($directions) {
      #{directed('padding-left')}: 10px;
    }
  }
}

.color-picker__button {
  height: 32px;
  width: 160px;
  display: flex;
  cursor: pointer;
  transition: all 0.3s;
  background: transparent;
  padding: 0;
  margin-top: 5px;

  @include themify($themes) {
    border: 1px solid themed("colorFieldsBorder");
  }

  &.active {
    border-color: $color-accent;
  }
}

.color-picker__color-view {
  height: 30px;
  width: 50px;
  border: 1px solid;
  // margin-right: -60px;
}

.color-picker__popover.popover {
  width: auto;

  .block-picker {
    & > div:first-child {
      display: none;
    }
  }

  .block-picker,
  .chrome-picker,
  .sketch-picker {
    box-shadow: none !important;
  }
}

.color-picker-title {
  font-size: 12px;
}
