@import "../settings/variable.scss";

.confidence_score_container {
  max-width: 100% !important;
  width: 800px !important;
  .modal__body {
    padding: 5px !important;
  }

  .confidence__score-title {
    width: 100%;
    text-align: left;
    padding: 20px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    margin: 0;
    word-break: break-word;
  }
  .card-body {
    padding: 0 !important;
  }

  .collapse__title {
    background-color: $color-hover;
  }

  .confidence__score_media {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.confidence__score_img {
  height: 200px;
  // width: 400px;
  object-fit: contain;
}

.confidence_score_tabs {
  min-height: 200px;
}

.confidence__score_tab_content {
  max-width: 800px;
}
