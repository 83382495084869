.video__container {
  .custom__title_video {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .blog__pad_left10 {
    padding-left: 10px;
  }
}

.video-list__action {
  display: flex;
  place-content: space-around;
  align-items: center;
}
