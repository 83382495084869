.page__container {
    .custom__title_pages {
        font-size: 12px;
        margin-bottom: 10px;
    }

    .page__pad10 {
        padding: 10px;
    }

    .page__pad_left10 {
        padding-left: 10px;
    }
}
